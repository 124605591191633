import React from "react";
import PropTypes from "prop-types";
import BlogPostsPageTemplate from "../templates/blog-posts-page-template";
import { Layout } from "../components/common";

const FeaturedBlogPosts = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;
    const featured = posts.filter(
        (post) => post.node.featured && post.node.slug != "data-schema"
    );
    return (
        <Layout>
            <BlogPostsPageTemplate
                title={"Featured Posts"}
                posts={featured}
                pageContext={pageContext}
                location={location}
            />
        </Layout>
    );
};

FeaturedBlogPosts.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default FeaturedBlogPosts;

export const query = graphql`
    query($skip: Int!) {
        allGhostPost(skip: $skip, sort: { order: DESC, fields: [updated_at] }) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
